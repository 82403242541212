import Router from 'vue-router'
import Vue from "vue"
Vue.use(Router)


export default new Router({
	mode: "history",
	routes: [
		{
			path: '/',
			redirect: "/main"
		},
		{
			path: '/',
			name: 'Home',
			component: () => import("../components/common/Home.vue"),
			children: [
				{
					path: '/main',
					name: 'Main',
					component: () => import("../components/page/Main.vue"),
				},

				{
					path: '/annotation',
					name: 'Annotation',
					component: () => import("../components/page/Annotation.vue"),
				},

				{
					path: '/str',
					name: 'STR',
					component: () => import("../components/page/SceneTextRecognition.vue"),
				},

				{
					path: '/kie',
					name: 'KIE',
					component: () => import("../components/page/KIE.vue"),
				},

				{
					path: '/easte',
					name: 'EASTE',
					component: () => import("../components/page/Easte.vue"),
				},

				{
					path: '/hwcr',
					name: 'HWCR',
					component: () => import("../components/page/HWCharRecognition.vue"),
				},

				{
					path: '/idcard',
					name: 'IdCard',
					component: () => import("../components/page/IdCard.vue"),
				},

				{
					path: '/businesslicense',
					name: 'BuisinessLicense',
					component: () => import("../components/page/BuisinessLicense.vue"),
				},

				{
					path: '/webimageLoc',
					name: 'WebImageLoc',
					component: () => import("../components/page/WebImageLoc.vue"),
				},

				{
					path: '/handwriting',
					name: 'HandWriting',
					component: () => import("../components/page/HandWriting.vue"),
				},

				{
					path: '/numbers',
					name: 'Numbers',
					component: () => import("../components/page/Numbers.vue"),
				},

				{
					path: '/passport',
					name: 'Passport',
					component: () => import("../components/page/Passport.vue"),
				},

				{
					path: '/licensePlate',
					name: 'LicensePlate',
					component: () => import("../components/page/LicensePlate.vue"),
				},

				{
					path: '/vinCode',
					name: 'VinCode',
					component: () => import("../components/page/VinCode.vue"),
				},

				{
					path: '/receipt',
					name: 'Receipt',
					component: () => import("../components/page/Receipt.vue"),
				},

				{
					path: '/docAnalysis',
					name: 'DocAnalysis',
					component: () => import("../components/page/DocAnalysis.vue"),
				},

				{
					path: '/formula',
					name: 'Formula',
					component: () => import("../components/page/Formula.vue"),
				},

				{
					path: '/meter',
					name: 'Meter',
					component: () => import("../components/page/Meter.vue"),
				},

				{
					path: '/form',
					name: 'Form',
					component: () => import("../components/page/Form.vue"),
				},

				{
					path: '/docanalysisoffice',
					name: 'DocAnalysisOffice',
					component: () => import("../components/page/DocAnalysisOffice.vue"),
				},

				{
					path: '/seal',
					name: 'Seal',
					component: () => import("../components/page/Seal.vue"),
				},

				{
					path: '/mixedmultivehicle',
					name: 'MixedMultiVehicle',
					component: () => import("../components/page/MixedMultiVehicle.vue"),
				},
			]

		},
	]

})


