<template>
  <div id="app">
	<router-view > </router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style>
  ::-webkit-scrollbar {
     width: 0 !important;
   }
   ::-webkit-scrollbar {
     width: 0 !important;height: 0;
   }
</style>
