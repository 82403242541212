import Vue from 'vue'
import App from './App.vue'
import router from './router/index';
window.$ = window.jQuery = require("jquery");

import { SVG } from '@svgdotjs/svg.js'
import '@svgdotjs/svg.panzoom.js'
import '@svgdotjs/svg.draggable.js'
import '@svgdotjs/svg.topoly.js'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'


import 'font-awesome/css/font-awesome.min.css'
import "@/resource/js/adminlte.min.js"
// import "@/resource/js/bootstrap.bundle.min.js"
import "bootstrap/dist/js/bootstrap.min.js"
import "@/resource/css/bootstrap.css"
import "@/resource/css/adminlte.min.css"



Vue.use(ElementUI)

Vue.prototype.$svg = SVG

Vue.config.productionTip = false

new Vue({
	router: router,
	render: h => h(App),
}).$mount('#app')
